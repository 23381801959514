<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard class="cards">
        <CCardBody>
          <div class="container flex-col col-md-12 m-auto" id="print" >
            <div class="inner-container flex-col m-auto" >
              <div>
                   <CButton color="btn btn-primary" @click="exportData" class="m-auto">
            {{ $t("message.export") }}</CButton
          >
          <button
          @click='download'
          type="button"
          class="btn btn-warning download"
          style=""
        >
        {{$t('message.download')}}
        </button>
              </div>
              </div>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import swal from 'sweetalert'
import $ from 'jquery'
export default {
  name: 'ExportData',
  data () {
    return {
    }
  },
  methods: {
    async exportData () {
      await this.$http
        .get(`${this.$hostUrl}export`)
        .then((response) => {
          swal(response.data.message)
          if (response.data.message === 'finished') {
            $('.download').addClass('opacity')
          }
        })
    },
    download () {
      this.$http
        .get(`${this.$hostUrl}download`, { responseType: 'blob' })
        .then((response) => {
          const fileUrl = window.URL.createObjectURL(response.data)
          const fileLink = document.createElement('a')

          fileLink.href = fileUrl
          fileLink.setAttribute('download', 'import-excel-template.xls')
          document.body.appendChild(fileLink)

          fileLink.click()
        })
    },
    goBack () {
      this.$router.push({ path: '/payments' })
    }
  }
}
</script>
<style scoped>
/* general styling */

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}
.btn-warning {
  padding: 0.25rem 0.5rem;
  margin: 10px;
  color: #4f5d73;
  background-color: #f9b115;
  border-color: #f9b115;
}
button {
  color: #fff;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  border-radius: 16px;
  padding: 5px;
  font-size: 1.2rem;
  background-color: #2a3647;
  width: 12rem;
  height: 3rem;
  border-width: 0px 2px 4px;
  border-style: solid;
  border-color: #2a3647;
  transition: 0.1s;
}

button:hover {
  background-color: #2a3647;
  border-color: #2a3647;
  cursor: pointer;
}

button:focus {
  outline: 0;
}

button:active {
  transition: 0.2s;
  border-width: 2px;
}

/* utility */

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.center {
  justify-content: center;
  align-items: center;
}

/* structure */

.container {
  background-color: #fff;
  border: thick dashed #2a3647;
  padding: 2rem;
}

.item-total,
.total {
  font-weight: 400;
}

.btn-container {
  padding: 1rem 0 0;
}
.download{
  opacity: 0;
}
.opacity{
  opacity: 1;
}

/* footer */
</style>
